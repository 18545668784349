<template>
    <div>
        <popup-view :isVisible="currentProduct !== null" @close="setCurrentProduct(null)">
            <edit-product-dialog/>
        </popup-view>
        <popup-view :isVisible="deleteOpenDialog" @close="deleteOpenDialog=false">
            <confirm-delete-dialog :product="currentSelect" @submitDeleteAction="submitDelete"
                                   @cancelDeleteAction="deleteOpenDialog = false"/>
        </popup-view>
        <page-header
            @actionClick="setCurrentProduct({})"
            iconName="shopping-cart.svg"
            actionIconName="plus.svg"
            :actionText="$t('productPage.addProduct')"
            :headerTitle="$t('productPage.products')"
        />
        <DataTable
            :value="getProductFiltered"
            v-model:filters="filters"
            filter-display="row"
            paginator
            ref="dt"
            :rows="500"
            :rows-per-page-options="[500, 1000, 2500, 5000, 10000]"
            table-style="min-width: 50rem"
            :global-filter-fields="['name', 'vatIn', 'vatOut', 'productGroup.name', 'productBarcodes']"
        >
            <template #header>
                <div class="flex justify-content-end" style="margin-top: 15px">
          <span class="p-input-icon-left">
            <i class="pi pi-search"/>
            <InputText v-model="filters['global'].value" placeholder="Keyword Search"/>
          </span>
                    <Button style="margin-left: 3px;" label="Export" @click="exportCSV($event)">
                        <inline-svg class="action-icon" :src="require('@/assets/icons/download.svg')"/>
                    </Button>

                </div>

            </template>
            <template #empty> {{ $t('productGroupPage.noFundResultTable') }}</template>
            <template #loading> {{ $t('productGroupPage.loadingResultTable') }}</template>
            <Column field="name" header="Name" style="max-width: 12rem">
                <template #body="{ data }">
                    <p>{{ data.name }}</p>
                    <div>
                <span class="allowed-option" v-for="allowedOption in data.allowedOptionGroups"
                      :key="allowedOption.productOptionGroupId">
                    {{ allowedOption.productGroup.name }},
                </span>
                    </div>
                </template>
                <template #filter="{ filterModel, filterCallback }">
                    <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter"
                               placeholder="Search by name"/>
                </template>
            </Column>
            <Column field="vatIn" header="In" sortable>
                <template #body="{ data }">
                    <span class="vat-in">€{{ data.priceVatInclIn + (data.weightPrice ? '/KG' : '') }} : {{
                            data.vatIn
                        }}%</span>
                </template>
            </Column>
            <Column field="vatIn" hidden>
                <template #body="{ data }">
                    <span>{{ data.vatIn }}</span>
                </template>
            </Column>
            <Column field="vatOut" header="Out" sortable>
                <template #body="{ data }">
                    <span class="vat-out">€{{
                            data.priceVatInclOut + (data.weightPrice ? '/KG' : '')
                        }} : {{ data.vatOut }}%</span>
                </template>
            </Column>
            <Column field="vatOut" hidden>
                <template #body="{ data }">
                    <span>{{ data.vatOut }}</span>
                </template>
            </Column>
            <Column field="productGroup.name" header="Group" sortable>
                <template #body="{ data }">
                    <span class="bread-crumb-base">{{ data.productGroup.name }}</span>
                </template>
                <template #filter="{ filterModel, filterCallback }">
                    <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter"
                               placeholder="Search by group"/>
                </template>
            </Column>
            <Column field="stock" header="Stock" sortable>
                <template #body="{ data }">
                    <button class="bread-crumb-base" title="stock" @click="openStock(data.productId)">{{
                            data.stock
                        }}
                    </button>
                </template>
            </Column>

            <Column field="color" header="Color" sortable>
                <template #body="{ data }">
                    <span class="bread-crumb-base" :style="{ backgroundColor: data.color }">{{ data.color }}</span>
                </template>
            </Column>

            <Column field="imageUrl" header="Image" sortable>
                <template #body="{ data }">
            <span v-if="data?.imageUrl">
              <a :href="`${data.imageUrl}`" target="_blank"> <img :src="`${data.imageUrl}`" width="45px"
                                                                  class="w-6rem border-round"/></a>
            </span>
                </template>
            </Column>
            <Column field="userHidden" :header="$t('productPage.visibility')" sortable>
                <template #body="{ data }">
                    <inline-svg
                        v-if="data.userHidden"
                        class="row-icon disabled"
                        :src="require('@/assets/icons/eye-off.svg')"
                    />
                    <inline-svg
                        v-else
                        class="row-icon"
                        :src="require('@/assets/icons/eye.svg')"
                    />
                </template>
            </Column>
            <Column field="orderIndex" header="Order" sortable>
                <template #body="{ data }">
                    <span class="bread-crumb-base">{{ data.orderIndex }}</span>
                </template>
            </Column>
            <Column field="quantity" header="Actions">
                <template #body="{data}">
                    <div class="edit-row-btn">
                        <button title="edit" @click="startEdit(data)">
                            <inline-svg :src="require('@/assets/icons/edit.svg')"/>
                        </button>
                        <button v-if="user.orgId === data.orgId" class="duplicate" title="duplicate" @click="duplicateProduct(data)">
                            <inline-svg :src="require('@/assets/icons/duplicate.svg')"/>
                        </button>
                      <button v-if="user.orgId === data.orgId" class="delete" title="delete" @click="openDeleteDialog(data)">
                        <inline-svg :src="require('@/assets/icons/trash.svg')"/>
                      </button>
                    </div>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
import {ref} from 'vue';
import {mapActions, mapState, mapMutations, mapGetters} from "vuex";
import {FilterMatchMode} from 'primevue/api';
import PageHeader from './components/PageHeader.vue'
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import PopupView from './components/PopupView.vue';
import EditProductDialog from './dialogs/EditProductDialog.vue';
import ConfirmDeleteDialog from './dialogs/ConfirmDeleteDialog.vue';
import {colorUtil} from '@/helpers/ColorUtil';

export default {
    name: "ProductsPage",
    components: {
        DataTable,
        Column,
        InputText,
        PageHeader,
        EditProductDialog,
        ConfirmDeleteDialog,
        PopupView,
    },
    data() {
        return {
            filters: ref({
                global: {value: null, matchMode: FilterMatchMode.CONTAINS},
                name: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
                'productGroup.name': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
            }),
            selectedFilterProduct: "",
            itemFiltered: [],
            deleteOpenDialog: false,
            currentSelect: {}
        }
    },
    methods: {
        ...mapActions('product', ['getProducts', 'deleteProduct', 'duplicateProduct']),
        ...mapActions('productGroup', ['getProductGroups']),
        ...mapMutations('product', ['setCurrentProduct', 'setFilterProduct', 'resetFilterProduct']),
        startEdit(item) {
            this.setCurrentProduct(item);
        },
        openDeleteDialog(item) {
            this.deleteOpenDialog = true;
            this.currentSelect = item;
        },
        submitDelete() {
            this.deleteProduct(this.currentSelect);
            this.deleteOpenDialog = false;
        },
        applyFilter(itemFilter) {
            if (itemFilter === 'resetFilter') {
                this.resetFilterProduct()
            } else {
                this.itemFiltered = this.products.filter(obj => {
                    return this.products = obj.productGroupId == itemFilter
                });
                this.setFilterProduct(this.itemFiltered)
            }
        },
        randomHSL(id) {
            return colorUtil.randomHSL(id);
        },
        openStock(productId) {
            this.$router.push({
                name: "stock",
                params: {productId: productId},
            });
        },
        exportCSV() {
            this.$refs.dt.exportCSV();
        }
    },
    computed: {
        ...mapState('product', ['products', 'currentProduct', 'productGroups']),
        ...mapState('productGroup', ['productGroups']),
        ...mapGetters('product', ['getProductFiltered']),
        ...mapState("account", ["user"]),
    },
    mounted() {
        this.getProducts();
        this.getProductGroups();
    }
}
</script>

<style lang="scss" scoped>
.allowed-option {
    color: $color-secondary-text;
    font-size: $font-small;
    font-weight: lighter;
}

.row-icon {
    width: 18px;
    height: 18px;

    &.disabled {
        color: $color-secondary-text !important;
    }
}
</style>