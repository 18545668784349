<template>
    <div>
        <popup-view :isVisible="productGroupState.editingGroup || productGroupState.isAddingGroup"
                    @close="setEditingGroup(null); setIsAddingGroup(false)"
        >
            <edit-product-group-dialog/>
        </popup-view>
        <popup-view :isVisible="deleteOpenDialog" @close="deleteOpenDialog = false">
            <confirm-delete-dialog :product="currentSelect" @submitDeleteAction="submitDelete"
                                   @cancelDeleteAction="deleteOpenDialog = false"/>
        </popup-view>
        <page-header
            class="header"
            @actionClick="setIsAddingGroup(true)"
            iconName="package.svg"
            actionIconName="plus.svg"
            :actionText="$t('productGroupPage.addGroup')"
            :headerTitle="$t('productGroupPage.productGroups')"
        />

        <DataTable
            :value="productGroupState.productGroups"
            v-model:filters="filters"
            filter-display="row"
            table-style="min-width: 50rem"
            :loading="loading"
            :global-filter-fields="['name', 'printer.name']"
        >
            <template #header>
                <div class="flex justify-content-end" style="margin-top: 15px">
            <span class="p-input-icon-left">
                <i class="pi pi-search"/>
                <InputText v-model="filters['global'].value" placeholder="Keyword Search"/>
            </span>
                </div>
            </template>
            <template #empty> {{ $t('productGroupPage.noFundResultTable') }}</template>
            <template #loading> {{ $t('productGroupPage.loadingResultTable') }}</template>
            <!-- <Column field="productGroupId" header="Code" sortable></Column> -->
            <Column field="name" header="Name" style="max-width: 12rem">
                <template #body="{ data }">
                    {{ data.name }}
                </template>
                <template #filter="{ filterModel, filterCallback }">
                    <InputText v-model="filterModel.value" type="text" @input="filterCallback()" class="p-column-filter"
                               placeholder="Search by name"/>
                </template>
            </Column>
            <Column field="vatIn" header="In" sortable></Column>
            <Column field="vatOut" header="Out" sortable></Column>
            <Column field="printer.name" header="Printers" sortable>
                <template #body="{ data }">
                    <span class="bread-crumb-base">{{ data.printer.name }}</span>
                </template>
            </Column>
            <Column field="color" header="Color" sortable>
                <template #body="{ data }">
                    <span class="bread-crumb-base" :style="{ backgroundColor: data.color }">{{ data.color }}</span>
                </template>
            </Column>
            <Column field="userHidden" :header="$t('productPage.visibility')" sortable>
                <template #body="{ data }">
                    <inline-svg
                        v-if="data.userHidden"
                        class="row-icon disabled"
                        :src="require('@/assets/icons/eye-off.svg')"
                    />
                    <inline-svg
                        v-else
                        class="row-icon"
                        :src="require('@/assets/icons/eye.svg')"
                    />
                </template>
            </Column>
            <Column field="webHidden" :header="$t('productPage.visibilityWeb')" sortable>
              <template #body="{ data }">
                <inline-svg
                    v-if="data.webHidden"
                    class="row-icon disabled"
                    :src="require('@/assets/icons/eye-off.svg')"
                />
                <inline-svg
                    v-else
                    class="row-icon"
                    :src="require('@/assets/icons/eye.svg')"
                />
              </template>
            </Column>
            <Column field="kitchenPrintEnabled" :header="$t('productGroupPage.KitchenPrintEnabled')" sortable>
                <template #body="{ data }">
                    <inline-svg
                        v-if="data.kitchenPrintEnabled"
                        class="row-icon"
                        :src="require('@/assets/icons/print-enabled.svg')"
                    />
                    <inline-svg
                        v-else
                        class="row-icon disabled"
                        :src="require('@/assets/icons/print-disabled.svg')"
                    />
                </template>
            </Column>
            <Column field="orderIndex" header="Order" sortable>
                <template #body="{ data }">
                    <span class="bread-crumb-base">{{ data.orderIndex }}</span>
                </template>
            </Column>
            <Column field="quantity" header="Actions">
                <template #body="{data}">
                    <div class="edit-row-btn">
                        <button class="delete" @click="openDeleteDialog(data)">
                            <inline-svg :src="require('@/assets/icons/trash.svg')"/>
                        </button>
                        <button @click="setEditingGroup(data)">
                            <inline-svg :src="require('@/assets/icons/edit.svg')"/>
                        </button>
                    </div>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script>
import {ref} from 'vue';
import {mapActions, mapState, mapMutations} from "vuex";
import {FilterMatchMode} from 'primevue/api';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import PageHeader from './components/PageHeader.vue';
import PopupView from './components/PopupView.vue';
import EditProductGroupDialog from './dialogs/EditProductGroupDialog.vue';
import ConfirmDeleteDialog from './dialogs/ConfirmDeleteDialog.vue';

export default {
    name: "ProductGroupsPage",
    components: {
        DataTable,
        Column,
        InputText,
        PageHeader,
        PopupView,
        EditProductGroupDialog,
        ConfirmDeleteDialog
    },
    data() {
        return {
            filters: ref({
                global: {value: null, matchMode: FilterMatchMode.CONTAINS},
                name: {value: null, matchMode: FilterMatchMode.STARTS_WITH},
                printer: {value: null, matchMode: FilterMatchMode.STARTS_WITH}
            }),
            deleteOpenDialog: false,
            currentSelect: {}
        }
    },
    computed: {
        ...mapState({
            productGroupState: 'productGroup'
        })
    },
    methods: {
        ...mapActions('productGroup', ['getProductGroups', 'deleteProductGroup']),
        ...mapMutations('productGroup', ['setIsAddingGroup', 'setEditingGroup']),
        openDeleteDialog(item) {
            this.deleteOpenDialog = true;
            this.currentSelect = item;
        },
        submitDelete() {
            this.deleteProductGroup(this.currentSelect);
            this.deleteOpenDialog = false;
        },
    },
    mounted() {
        this.getProductGroups();
    }
}
</script>

<style lang="scss" scoped>
.edit-row-btn {
    column-gap: 10px;
    background: none !important;
}
.row-icon {
    width: 18px;
    height: 18px;
    &.disabled {
        color: $color-secondary-text !important;
    }
}
</style>