<template>
  <div>
    <page-header class="header" iconName="statistic.svg" :headerTitle="$t('shiftStatPage.header')"
    :action-ternary-icon-name="(!startDate || !endDate) ? 'calculate.svg' : ''" @actionTernaryClick="viewDetailShift" :action-ternary-text="(!startDate || !endDate) ? 'Details' : ''"
    ></page-header>
    <div class="row row-card detailPage mb-3">
      <div class="card" name="list" tag="div">
        <card-shift :item="shiftData.shift" :from="'detailPage'" v-if="!startDate || !endDate" />
        <div class="d-flex" v-if="startDate || endDate">
          <div><span class="bread-crumb-base mr-2">{{startDate}}</span></div>
          <div><span class="bread-crumb-base">{{endDate}}</span></div>
        </div>
      </div>
    </div>
    
    <div class="row">
      <div class="main-content col-12 col-md-9 order-2 order-md-1">
        <DataTable :value="shiftDataX?.orderedProductTotalPerUser" v-model:filters="filters" paginator
          :rows="10" :rows-per-page-options="[10, 20, 50, 100, 200, 500]" table-style="min-width: 50rem"
          :loading="loading" :global-filter-fields="['name']">
          <template #header>
            <div class="flex justify-content-end" style="margin-top: 22px">
              <span class="bread-crumb-base">USER</span>&nbsp;
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
              </span>
            </div>
          </template>
          <template #empty> {{ $t('productGroupPage.noFundResultTable') }} </template>
          <template #loading> {{ $t('productGroupPage.loadingResultTable') }} </template>
          <Column field="name" header="Name" sortable>
            <template #body="{ data }">
              <span>{{ data.name }}</span>
            </template>
          </Column>
          <Column field="count" header="Count" sortable>
            <template #body="{ data }">
              {{ data.totalCount }}
            </template>
          </Column>
          <Column field="totalOffered" header="Total Offered" sortable>
            <template #body="{ data }">
              €{{ data.totalData.totalOffered.toFixed(2) }}
            </template>
          </Column>
          <Column field="totalCash" header="Total Cash" sortable>
            <template #body="{ data }">
              €{{ data.totalData.totalCash.toFixed(2) }}
            </template>
          </Column>
          <Column field="totalCard" header="Total Card" sortable>
            <template #body="{ data }">
              €{{ data.totalData.totalCard.toFixed(2) }}
            </template>
          </Column>
          <Column field="totalBankTransfer" header="Total Bank Transfer" sortable>
            <template #body="{ data }">
              €{{ data.totalData.totalBankTransfer.toFixed(2) }}
            </template>
          </Column>
          <Column field="totalError" header="Total Error" sortable>
            <template #body="{ data }">
              €{{ data.totalData.totalError.toFixed(2) }}
            </template>
          </Column>
          <Column field="total" header="Total" sortable>
            <template #body="{ data }">
              €{{ data.totalData.total.toFixed(2) }}
            </template>
          </Column>
        </DataTable>

        <DataTable :value="shiftDataX?.orderedProductTotalPerGroup" v-model:filters="filters" paginator
          :rows="10" :rows-per-page-options="[10, 20, 50, 100, 200, 500]" table-style="min-width: 50rem"
          :loading="loading" :global-filter-fields="['name']">
          <template #header>
            <div class="flex justify-content-end" style="margin-top: 22px">
              <span class="bread-crumb-base">GROUP</span>&nbsp;
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
              </span>
            </div>
          </template>
          <template #empty> {{ $t('productGroupPage.noFundResultTable') }} </template>
          <template #loading> {{ $t('productGroupPage.loadingResultTable') }} </template>
          <Column field="name" header="Name" sortable>
            <template #body="{ data }">
              <span>{{ data.name }}</span>
            </template>
          </Column>
          <Column field="totalCount" header="Count" sortable>
            <template #body="{ data }">
              {{ data.totalCount }}
            </template>
          </Column>
          <Column field="totalCharged" header="Total" sortable>
            <template #body="{ data }">
              €{{ data.totalCharged.toFixed(2) }}
            </template>
          </Column>
        </DataTable>

        <DataTable :value="shiftDataX?.orderedProductTotalPerProduct" v-model:filters="filters" paginator
          :rows="10" :rows-per-page-options="[10, 20, 50, 100, 200, 500]" table-style="min-width: 50rem"
          :loading="loading" :global-filter-fields="['name']">
          <template #header>
            <div class="flex justify-content-end" style="margin-top: 22px">
              <span class="bread-crumb-base">PRODUCT</span>&nbsp;
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
              </span>
            </div>
          </template>
          <template #empty> {{ $t('productGroupPage.noFundResultTable') }} </template>
          <template #loading> {{ $t('productGroupPage.loadingResultTable') }} </template>
          <Column field="name" header="Name" sortable>
            <template #body="{ data }">
              <span>{{ data.name }}</span>
            </template>
          </Column>
          <Column field="totalCount" header="Count" sortable>
            <template #body="{ data }">
              {{ data.totalCount }}
            </template>
          </Column>
          <Column field="totalCharged" header="Total" sortable>
            <template #body="{ data }">
              €{{ data.totalCharged.toFixed(2) }}
            </template>
          </Column>
        </DataTable>
      </div>

      <div class="col-12 col-md-3 order-1 order-md-2 mt-5">
        <div class="col-12 sticky-top">
          <div class="ml-md-3 total-container card">
            <p class="header mb-3">{{ $t("generic.total") }}</p>
            <div class="text-center">
              <span class="float-left">{{ $t("paymentsPage.totalCash") }}</span>
              <span v-if="shiftDataX != null" class="float-right">€{{
                shiftDataX?.totalData?.totalCash.toFixed(2)
              }}</span>
              <span v-else class="float-right">€{{ (0.0).toFixed(2) }}</span>
            </div>

            <div class="text-center">
              <span class="float-left">{{ $t("paymentsPage.totalCard") }}</span>
              <span v-if="shiftDataX != null" class="float-right">€{{
                shiftDataX?.totalData?.totalCard.toFixed(
                  2
                )
              }}</span>
              <span v-else class="float-right">€{{ (0.0).toFixed(2) }}</span>
            </div>

            <div class="text-center">
              <span class="float-left">{{ $t("paymentsPage.totalBankTransfer") }}</span>
              <span v-if="shiftDataX != null" class="float-right">€{{
                shiftDataX?.totalData?.totalBankTransfer.toFixed(
                  2
                )
              }}</span>
              <span v-else class="float-right">€{{ (0.0).toFixed(2) }}</span>
            </div>

            <div class="text-center">
              <span class="float-left">{{
                $t("paymentsPage.totalOffered")
              }}</span>
              <span v-if="shiftDataX != null" class="float-right">€{{
                shiftDataX?.totalData?.totalOffered.toFixed(
                  2
                )
              }}</span>
              <span v-else class="float-right">€{{ (0.0).toFixed(2) }}</span>
            </div>

            <div class="text-center">
              <span class="float-left">{{
                  $t("paymentsPage.totalError")
                }}</span>
              <span v-if="shiftDataX != null" class="float-right">€{{
                  shiftDataX?.totalData?.totalError.toFixed(
                      2
                  )
                }}</span>
              <span v-else class="float-right">€{{ (0.0).toFixed(2) }}</span>
            </div>

            <hr />

            <div class="text-center">
              <span class="float-left default-header">{{
                $t("generic.total")
              }}</span>
              <span v-if="shiftDataX != null" class="float-right default-header">€{{
                shiftDataX?.totalData?.total.toFixed(
                  2
                )
              }}</span>
              <span v-else class="float-right default-header">€{{ (0.0).toFixed(2) }}</span>
            </div>
          </div>
          <div v-if="shiftDataX && shiftDataX?.vatSummaries
            " class="ml-md-3 total-container card mt-3">
            <p class="header mb-3">{{ $t("generic.vat") }}</p>
            <div v-for="vatSummary in shiftDataX?.vatSummaries" :key="vatSummary.vatBasePercent" class="mb-3 d-card">
              <span>{{ vatSummary.vatBasePercent }}%</span>
              <div class="text-center">
                <span class="float-left">{{
                  $t("paymentsPage.totalVatExcl")
                }}</span>
                <span class="float-right">€{{ vatSummary.totalVatExcl.toFixed(2) }}</span>
              </div>
              <div class="text-center">
                <span class="float-left">{{ $t("generic.vat") }}</span>
                <span class="float-right">€{{ vatSummary.totalVat.toFixed(2) }}</span>
              </div>
              <div class="text-center">
                <span class="float-left">{{
                  $t("paymentsPage.totalVatIncl")
                }}</span>
                <span class="float-right">€{{ vatSummary.totalVatIncl.toFixed(2) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { mapActions, mapState, mapMutations } from "vuex";
import { FilterMatchMode } from 'primevue/api';
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import PageHeader from "./components/PageHeader.vue";
import CardShift from "./components/CardShift.vue";

export default {
  name: "shiftStatPage",
  components: {
    DataTable,
    Column,
    InputText,
    PageHeader,
    CardShift,
  },
  computed: {
    ...mapState("shift", ["shiftData", "shiftDataX"]),
  },
  data() {
    return {
      filters: ref({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      }),
      currentShiftId: "",
      startDate: "",
      endDate: ""
    };
  },
  methods: {
    ...mapActions("shift", [
      "getShiftStatsDataXDate",
      "getShiftIdData",
      "getShiftIdDataX",
    ]),
      viewDetailShift() {
      this.$router.push({
        name: "ShiftDetailPage",
        params: { shiftId: this.currentShiftId },
      });
    }
  },
  mounted() {
    const segments = window.location.href.split("/");
    const shiftId = segments[segments.length - 1];
    this.currentShiftId = shiftId;
    const startTimestamp = Number(this.$route.params.startdate);
    const endTimestamp = Number(this.$route.params.enddate);

    if (!isNaN(startTimestamp) && !isNaN(endTimestamp)) {
      this.startDate = new Date(startTimestamp).toLocaleDateString();
      this.endDate = new Date(endTimestamp).toLocaleDateString();

      this.getShiftStatsDataXDate({ startDate: startTimestamp, endDate: endTimestamp });
    } else {
      this.getShiftIdData(shiftId);
      this.getShiftIdDataX(shiftId);
    }
  },
};
</script>

<style lang="scss" scoped>
.date {
  display: inline-block;
  font-size: $font-small;
  margin-right: $default-edge-spacing-l;
}

.card {
  border-radius: $radius-small;
  background-color: $color-input-back;
  margin-top: $default-edge-spacing-xxl;
  padding: $default-edge-spacing-xl !important;
}

.total-container {
  .header {
    font-size: $font-title-big;
    font-weight: 600;
  }

  .float-left {
    color: $color-primary-text;
  }

  .float-right {
    color: $color-secondary-text;
  }
}

hr {
  border-color: $color-secondary-text;
  border-radius: 15px;
  opacity: 0.15;
}

.d-card {
  display: flex;
  flex-direction: column;
}
</style>
