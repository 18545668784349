<template>
  <div>
    <popup-view :isVisible="deleteOpenDialog" @close="deleteOpenDialog=false">
      <confirm-delete-dialog :product="currentSelect" @submitDeleteAction="submitDelete"
        @cancelDeleteAction="deleteOpenDialog = false" />
    </popup-view>
    <page-header class="header" iconName="activity.svg" :headerTitle="$t('shiftDetailPage.header')"
      actionIconName="download.svg" @actionClick="submitDownloadZ" :actionText="$t('generic.downloadZ')"
      actionSecondaryIconName="download.svg" @actionSecondaryClick="submitDownloadX" :actionSecondaryText="$t('generic.downloadX')"
      action-ternary-icon-name="statistic.svg" @actionTernaryClick="viewStatShift" action-ternary-text="Stats"
      >
    </page-header>
    <div class="row">
        <div class="main-content col-12 col-md-9 order-1 order-md-1 row-card detailPage mb-3 col-9 ">
          <div class="card">
            <card-shift :item="shiftData.shift" :from="'detailPage'" />
          </div>
        </div>

        <div v-if="!shiftData.shift?.endTimestamp" class="form-input-container col-12 col-md-3 order-2 order-md-2">
          <div class="col-12 sticky-top">
            <div class="ml-md-3 total-container card">
              <p class="header p-1">{{ $t("shiftPage.HeaderShiftClose") }}</p>
                <div class="text-center">
                  <div class="form-input-container">
                    <DesignInput class="p-2" v-model:customValue="myCurrentCash" :hint="$t('shiftPage.totalCashClose')"/>
                    <DesignInput class="p-2" v-model:customValue="myComment" :hint="$t('shiftPage.endComment')"/>
                    <design-select class="p-2" :hint="$t('shiftPage.paymentType')" v-model:customValue="myPaymentTypeForForcedClose">
                      <option value="CASH">CASH</option>
                      <option value="CARD">CARD</option>
                    </design-select>
                  </div><hr />
                  <div class="button-container row justify-content-end">
                    <design-button class="button"  @customClick="endShiftNow()" > {{$t('generic.save')}} </design-button >
                  </div>
                </div>
            </div>
          </div>
        </div>
    </div>
    <div class="row">
      <div class="main-content col-12 col-md-9 order-2 order-md-1">
        <DataTable :value="shiftData?.clientAccountHistoryData?.clientAccounts"
                   v-model:filters="filters"
                   paginator
                   :rows="500"
                   :rows-per-page-options="[500, 1000, 2500, 5000, 10000]"
                   table-style="min-width: 50rem"
          :loading="loading" :global-filter-fields="['table.tableNumber', 'status', 'payTypes', 'user.username', 'totalData.total']">
          <template #header>
            <div class="flex justify-content-end" style="margin-top: 22px">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
              </span>
            </div>
          </template>
          <template #empty> {{ $t('productGroupPage.noFundResultTable') }} </template>
          <template #loading> {{ $t('productGroupPage.loadingResultTable') }} </template>
          <Column field="clientAccountId" header="Date" sortable>
            <template #body="{ data }">
              <span>
                {{ data.openedTimestamp.toDate() }} <br />
                <span class="default-text">{{
                  data.openedTimestamp.toTime()
                }}</span>
              </span>
            </template>
          </Column>
          <Column field="id" header="id" sortable>
              <template #body="{ data }">
                  {{ data?.clientAccountId}} <br>
              </template>
          </Column>

          <Column field="name" header="Name" sortable>
            <template #body="{ data }">
              {{ data.customName }}
            </template>
          </Column>
          <Column field="table" header="Table" sortable>
            <template #body="{ data }">
              <span v-if="data.table">{{ data?.table?.tableNumber }}</span>
              <span v-else>{{ $t("generic.takeaway") }}</span>
            </template>
          </Column>
          <Column field="status" header="Status" sortable>
            <template #body="{ data }">
              <span>{{ data?.status }}</span>
            </template>
          </Column>
          <Column field="payTypes" header="Type" sortable>
            <template #body="{ data }">
              <span v-for="(type, index) in data.payTypes" :key="type"
                :class="['bread-crumb-base', { 'not-last': index !== data.payTypes.length - 1 }]">
                {{ type }}
              </span>
            </template>
          </Column>
          <Column field="table" header="Total" sortable>
            <template #body="{ data }">
              <span class="default-text">€{{ data?.totalData?.total.toFixed(2) }}</span>
            </template>
          </Column>
          <Column field="user" header="User" sortable>
            <template #body="{ data }">
              <span class="default-text">{{ data?.user?.username }}</span>
            </template>
          </Column>
          <Column field="actions" header="Actions">
            <template #body="{ data }">
              <div class="edit-row-btn">
                <button class="delete" @click="openDeleteDialog(data)"><inline-svg
                    :src="require('@/assets/icons/trash.svg')" /></button>
                <button @click="openAccount(data.clientAccountId)"><inline-svg
                    :src="require('@/assets/icons/align-left.svg')" /></button>
              </div>
            </template>
          </Column>
        </DataTable>
      </div>

      <div class="col-12 col-md-3 order-1 order-md-2 mt-5">
        <div class="col-12 sticky-top">
          <div class="ml-md-3 total-container card">
            <p class="header mb-3">{{ $t("generic.total") }}</p>
            <div class="text-center">
              <span class="float-left">{{ $t("paymentsPage.totalCash") }}</span>
              <span v-if="shiftData != null" class="float-right">€{{
                shiftData?.clientAccountHistoryData?.totalData?.totalCash.toFixed(2)
              }}</span>
              <span v-else class="float-right">€{{ (0.0).toFixed(2) }}</span>
            </div>

            <div class="text-center">
              <span class="float-left">{{ $t("paymentsPage.totalCard") }}</span>
              <span v-if="shiftData != null" class="float-right">€{{
                shiftData?.clientAccountHistoryData?.totalData?.totalCard.toFixed(
                  2
                )
              }}</span>
              <span v-else class="float-right">€{{ (0.0).toFixed(2) }}</span>
            </div>

            <div class="text-center">
              <span class="float-left">{{ $t("paymentsPage.totalBankTransfer") }}</span>
              <span v-if="shiftData != null" class="float-right">€{{
                shiftData?.clientAccountHistoryData?.totalData?.totalBankTransfer.toFixed(
                  2
                )
              }}</span>
              <span v-else class="float-right">€{{ (0.0).toFixed(2) }}</span>
            </div>

            <div class="text-center">
              <span class="float-left">{{
                $t("paymentsPage.totalOffered")
              }}</span>
              <span v-if="shiftData != null" class="float-right">€{{
                shiftData?.clientAccountHistoryData?.totalData?.totalOffered.toFixed(
                  2
                )
              }}</span>
              <span v-else class="float-right">€{{ (0.0).toFixed(2) }}</span>
            </div>

            <div class="text-center">
              <span class="float-left">{{
                  $t("paymentsPage.totalError")
                }}</span>
              <span v-if="shiftData != null" class="float-right">€{{
                  shiftData?.clientAccountHistoryData?.totalData?.totalError.toFixed(
                      2
                  )
                }}</span>
              <span v-else class="float-right">€{{ (0.0).toFixed(2) }}</span>
            </div>


            <hr />

            <div class="text-center">
              <span class="float-left default-header">{{
                $t("generic.total")
              }}</span>
              <span v-if="shiftData != null" class="float-right default-header">€{{
                shiftData?.clientAccountHistoryData?.totalData?.total.toFixed(
                  2
                )
              }}</span>
              <span v-else class="float-right default-header">€{{ (0.0).toFixed(2) }}</span>
            </div>
          </div>
          <div v-if="shiftData && shiftData?.clientAccountHistoryData?.vatSummaries
            " class="ml-md-3 total-container card mt-3">
            <p class="header mb-3">{{ $t("generic.vat") }}</p>
            <div v-for="vatSummary in shiftData?.clientAccountHistoryData
              ?.vatSummaries" :key="vatSummary.vatBasePercent" class="mb-3 d-card">
              <span>{{ vatSummary.vatBasePercent }}%</span>
              <div class="text-center">
                <span class="float-left">{{
                  $t("paymentsPage.totalVatExcl")
                }}</span>
                <span class="float-right">€{{ vatSummary.totalVatExcl.toFixed(2) }}</span>
              </div>
              <div class="text-center">
                <span class="float-left">{{ $t("generic.vat") }}</span>
                <span class="float-right">€{{ vatSummary.totalVat.toFixed(2) }}</span>
              </div>
              <div class="text-center">
                <span class="float-left">{{
                  $t("paymentsPage.totalVatIncl")
                }}</span>
                <span class="float-right">€{{ vatSummary.totalVatIncl.toFixed(2) }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { mapActions, mapState } from "vuex";
import { FilterMatchMode } from 'primevue/api';
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import PageHeader from "./components/PageHeader.vue";
import CardShift from "./components/CardShift.vue";
import PopupView from './components/PopupView.vue';
import ConfirmDeleteDialog from './dialogs/ConfirmDeleteDialog.vue';
import DesignButton from "@/views/components/DesignButton.vue";
import DesignInput from "@/views/components/DesignInput.vue";
import DesignSelect from "@/views/components/DesignSelect.vue";

export default {
  name: "shiftDetailPage",
  components: {
    DesignSelect,
    DesignInput, DesignButton,
    DataTable,
    Column,
    InputText,
    PageHeader,
    CardShift,
    PopupView,
    ConfirmDeleteDialog
  },
  computed: {
    ...mapState("shift", ["shiftData", "shiftMessage"]),
  },
  data() {
    return {
      filters: ref({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      }),
      currentShiftId: "",
      deleteOpenDialog: false,
    };
  },
  methods: {
    ...mapActions("shift", [
      "getShiftIdData",
      "deleteAccountWithHistory",
      "getShiftDownloadXData",
      "getShiftDownloadZData",
      "forceClosShiftForMixedPayment"
    ]),
    openAccount(accountId) {
      this.$router.push({
        name: "AccountSummary",
        params: { accountId: accountId },
      });
    },
    openDeleteDialog(item) {
      this.deleteOpenDialog = true;
      this.currentSelect = item;
    },
    submitDelete() {
      this.deleteAccountWithHistory(this.currentSelect.clientAccountId);
      this.deleteOpenDialog = false;
    },
    submitDownloadX() {
      this.getShiftDownloadXData("shiftDetail");
    },
    submitDownloadZ() {
      this.getShiftDownloadZData("shiftDetail");
    },
    viewStatShift() {
      this.$router.push({
        name: "ShiftStatPage",
        params: { shiftId: this.currentShiftId  },
      });
    },
    endShiftNow() {
      if(window.confirm(this.$t('shiftPage.MessageCloseShift'))) {
        this.forceClosShiftForMixedPayment({myComment: this.myComment, myCurrentCash: this.myCurrentCash, myPaymentTypeForForcedClose: this.myPaymentTypeForForcedClose});
      }
    }
  },
  mounted() {
    const segments = window.location.href.split("/");
    const shiftId = segments[segments.length - 1];
    this.currentShiftId = shiftId;

    this.getShiftIdData(shiftId);
  },
};
</script>

<style lang="scss" scoped>
.date {
  display: inline-block;
  font-size: $font-small;
  margin-right: $default-edge-spacing-l;
}

.card {
  border-radius: $radius-small;
  background-color: $color-input-back;
  margin-top: $default-edge-spacing-xxl;
  padding: $default-edge-spacing-xl !important;
}

.total-container {
  .header {
    font-size: $font-title-big;
    font-weight: 600;
  }

  .float-left {
    color: $color-primary-text;
  }

  .float-right {
    color: $color-secondary-text;
  }
}

hr {
  border-color: $color-secondary-text;
  border-radius: 15px;
  opacity: 0.15;
}

.d-card {
  display: flex;
  flex-direction: column;
}
</style>
