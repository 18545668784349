<template>
  <div>
    <page-header
        class="header"
        iconName="activity.svg"
        actionIconName="search.svg"
        @actionClick="submitSearch"
        :actionText="$t('generic.search')"
        :headerTitle="$t('accountHistoryPage.header')"
        :slotHint="$t('generic.filter')"
    >
      <design-input v-model:customValue="startDateTime" type="datetime-local"  id="startDateTime" class="date w-100" name="startDateTime"/>
      <design-input v-model:customValue="endDateTime" type="datetime-local" id="endDateTime" class="date w-100" name="endDateTime"/>
    </page-header>
    <div class="row">
      <div class="main-content col-12 col-md-9 order-2 order-md-1">
          <DataTable :value="accountsData?.clientAccounts"
                     v-model:filters="filters"
                     paginator
                     :rows="500"
                     :rows-per-page-options="[500, 1000, 2500, 5000, 10000]"
                     table-style="min-width: 50rem"
                     :loading="loading"
                     :global-filter-fields="['status', 'payTypes', 'table.tableNumber', 'user.username']">
          <template #header>
            <div class="d-flex" style="margin-top: 22px">
              <div class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
              </div>
            </div>
          </template>
          <template #empty> {{ $t('productGroupPage.noFundResultTable') }} </template>
          <template #loading> {{ $t('productGroupPage.loadingResultTable') }} </template>
          <Column v-if="isSuperUser" field="clientAccountId" header="Id" sortable>
              <template #body="{ data }">
                  {{data.clientAccountId}}
              </template>
          </Column>
          <Column field="openedTimestamp" header="Date" sortable>
            <template #body="{ data }">
              {{ data.openedTimestamp.toDate()}} <br>
              <span class="default-text">{{ data.openedTimestamp.toTime()}}</span>
            </template>
          </Column>
          <Column field="table" header="Table" sortable>
            <template #body="{ data }">
              <span v-if="data.table">{{data.table.tableNumber}}</span>
              <span v-else>{{$t('generic.takeaway')}}</span>
            </template>
          </Column>
          <Column field="customName" header="Name" sortable>
            <template #body="{ data }">
              {{data.customName}}
            </template>
          </Column>
          <Column field="status" header="Status" sortable>
            <template #body="{ data }">
              <span class="bread-crumb-base ">{{data.status}}</span>
            </template>
          </Column>
          <Column field="payTypes" header="Type" sortable>
            <template #body="{ data }">
              <span v-for="type in data.payTypes" :key="type" class="bread-crumb-base mr-1">{{ type }}</span>
            </template>
          </Column>
          <Column field="total" header="Total" sortable>
            <template #body="{ data }">
              €{{data.totalData.total.toFixed(2)}}
            </template>
          </Column>
          <Column field="username" header="User" sortable>
            <template #body="{ data }">
              {{data.user.username}}
            </template>
          </Column>
          <Column field="actions" header="Actions" sortable>
            <template #body="{ data }">
              <div class="edit-row-btn">
                <button class="delete" @click="deleteAccount(data.clientAccountId)" ><inline-svg :src="require('@/assets/icons/trash.svg')" /></button>
                <button @click="openAccount(data.clientAccountId)" ><inline-svg :src="require('@/assets/icons/align-left.svg')" /></button>
              </div>
            </template>
          </Column>
        </DataTable>
      </div>
      <div class="col-12 col-md-3 order-1 order-md-2 mt-md-5">
        <div class="col-12">
          <div class="ml-md-3 total-container card ">
            <p class="header mb-3">{{$t('generic.total')}}</p>
            <div class="text-center">
              <span class="float-left">{{$t('paymentsPage.totalCash')}}</span>
              <span v-if="accountsData != null" class="float-right">€{{ accountsData.totalData.totalCash.toFixed(2) }}</span>
              <span v-else class="float-right">€{{ 0.0.toFixed(2) }}</span>
            </div>
            <div class="text-center">
              <span class="float-left">{{$t('paymentsPage.totalCard')}}</span>
              <span v-if="accountsData != null" class="float-right">€{{ accountsData.totalData.totalCard.toFixed(2) }}</span>
              <span v-else class="float-right">€{{ 0.0.toFixed(2) }}</span>
            </div>

            <div class="text-center">
              <span class="float-left">{{$t('paymentsPage.totalBankTransfer')}}</span>
              <span v-if="accountsData != null" class="float-right">€{{ accountsData.totalData.totalBankTransfer.toFixed(2) }}</span>
              <span v-else class="float-right">€{{ 0.0.toFixed(2) }}</span>
            </div>
            <div class="text-center">
              <span class="float-left">{{$t('paymentsPage.totalOffered')}}</span>
              <span v-if="accountsData != null" class="float-right">€{{ accountsData.totalData.totalOffered.toFixed(2) }}</span>
              <span v-else class="float-right">€{{ 0.0.toFixed(2) }}</span>
            </div>
            <div class="text-center">
              <span class="float-left">{{$t('paymentsPage.totalError')}}</span>
              <span v-if="accountsData != null" class="float-right">€{{ accountsData.totalData.totalError.toFixed(2) }}</span>
              <span v-else class="float-right">€{{ 0.0.toFixed(2) }}</span>
            </div>
            <hr>
            <div class="text-center">
              <span class="float-left default-header">{{$t('generic.total')}}</span>
              <span v-if="accountsData != null" class="float-right default-header">€{{ accountsData.totalData.total.toFixed(2) }}</span>
              <span v-else class="float-right default-header">€{{ 0.0.toFixed(2) }}</span>
            </div>

          </div>
          <div v-if="accountsData && accountsData.vatSummaries" class="ml-md-3 total-container card mt-3">
            <p class="header mb-3">{{$t('generic.vat')}}</p>
            <div v-for="vatSummary in accountsData.vatSummaries" :key="vatSummary.vatBasePercent" class="mb-3 d-card">
              <span>{{vatSummary.vatBasePercent}}%</span>
              <div class="text-center">
                <span class="float-left">{{$t('paymentsPage.totalVatExcl')}}</span>
                <span class="float-right">€{{ vatSummary.totalVatExcl.toFixed(2) }}</span>
              </div>
              <div class="text-center">
                <span class="float-left">{{$t('generic.vat')}}</span>
                <span class="float-right">€{{ vatSummary.totalVat.toFixed(2) }}</span>
              </div>
              <div class="text-center">
                <span class="float-left">{{$t('paymentsPage.totalVatIncl')}}</span>
                <span class="float-right">€{{ vatSummary.totalVatIncl.toFixed(2) }}</span>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue';
import { FilterMatchMode } from 'primevue/api';
import { mapActions, mapState, mapMutations } from "vuex";
import PageHeader from './components/PageHeader.vue'
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import Calendar from 'primevue/calendar';
import DesignInput from "@/views/components/DesignInput.vue";
export default {
  name: "AccountHistoryPage",
  components: {
    DesignInput,
    PageHeader,
    DataTable,
    Column,
    InputText,
  },
  computed: {
    ...mapState('analytics', ['accountsData', 'queryStartDateTime', 'queryEndDateTime']),
    ...mapState('account', ['isSuperUser']),
    startDateTime: {
      get() {
        return this.queryStartDateTime;
      },
      set(newV) {
        this.setQueryStartDateTime(newV);
      }
    },
    endDateTime: {
      get() {
        return this.queryEndDateTime;
      },
      set(newV) {
        this.setQueryEndDateTime(newV);
      }
    }
  },
  data() {
    return {
      filters: ref({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      }),
      dates: null
    };
  },
  methods: {
    ...mapActions('analytics', ['getAccountsHistory', 'deleteAccountWithHistory']),
    ...mapMutations('analytics', ['setQueryStartDateTime', 'setQueryEndDateTime']),
    submitSearch() {
      this.getAccountsHistory();
    },
    openAccount(accountId) {
      this.$router.push({name: 'AccountSummary', params: {accountId: accountId}});
    },
    deleteAccount(accountId) {
      var confirmed = confirm(this.$t("generic.deleteConfirm"));
      if (confirmed) {
        this.deleteAccountWithHistory(accountId);
      }
    }
  },
  mounted() {
    this.submitSearch();
  }
}
</script>
<style lang="scss" scoped>
button {
  &.action-button {
    background-color: $color-primary-blue;
    height: 41px;
  }
}
.card {
  border-radius: $radius-small;
  background-color: $color-input-back;
  margin-top: $default-edge-spacing-xxl;
  padding: $default-edge-spacing-xl !important;
}
.total-container {
  .header {
    font-size: $font-title-big;
    font-weight: 600;
    padding-left: 10px;
    border-left: 3px solid $color-primary-blue;
  }
  .float-left {
    color: $color-primary-text;
  }
  .float-right {
    color: $color-secondary-text;
  }
}
hr {
  border-color: $color-secondary-text;
  border-radius: 15px;
  opacity: 0.15;
}
.d-card {
  display: flex;
  flex-direction: column
}
</style>