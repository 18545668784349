import apiClient from "../helpers/ApiClient";
import { handleError, authHeader } from "../helpers/ApiClient";

export const shiftService = {
    getShiftService,
    getShiftCurrentService,
    getShiftLatestService,
    getShiftIdService,
    getShiftDetailDataXService,
    getShiftStatsDataXDateService,
    getShiftDetailDownloadXService,
    getShiftDetailDownloadZService,
    getShiftDownloadXService,
    getShiftDownloadZService,
    deleteAccount,
    forceCloseShift
};

function getShiftService(startDateString, endDateString) {
    let startMs = new Date(startDateString).getTime();
    let endMs = new Date(endDateString).getTime();

    return apiClient
      .get("/shift", {
        params: { startDate: startMs, endDate: endMs },
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => handleError(error));
}
function getShiftIdService(shiftId) {
  return apiClient
    .get(`/shift/${shiftId}`, { headers: authHeader() })
    .then((response) => {
      return response.data;
    })
    .catch((error) => handleError(error));
}
function getShiftDetailDataXService(shiftId) {
  return apiClient
    .get(`/shift/${shiftId}/X`, { headers: authHeader() })
    .then((response) => {
      return response.data;
    })
    .catch((error) => handleError(error));
}
function getShiftStatsDataXDateService(startDate, endDate) {
  return apiClient
    .get(`/shift/X`, { 
      params: { startDate: startDate, endDate: endDate },
      headers: authHeader() 
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => handleError(error));
}
function getShiftCurrentService() {
  return apiClient
    .get("/shift/current", { headers: authHeader() })
    .then((response) => {
      return response.data;
    })
    .catch((error) => handleError(error));
}
function getShiftLatestService() {
  return apiClient
    .get("/shift/latest", { headers: authHeader() })
    .then((response) => {
      return response.data;
    })
    .catch((error) => handleError(error));
}
function getShiftDetailDownloadXService(shiftId) {
  return apiClient
    .get(`/shift/${shiftId}/X/download`, {
      headers: authHeader(),
      responseType: "blob",
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => handleError(error));
}
function getShiftDetailDownloadZService(shiftId) {
  return apiClient
    .get(`/shift/${shiftId}/Z/download`, {
      headers: authHeader(),
      responseType: "blob",
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => handleError(error));
}
function getShiftDownloadXService(page, startDateString, endDateString) {
  console.log(page, startDateString, endDateString);
  let startMs = new Date(startDateString).getTime();
  let endMs = new Date(endDateString).getTime();
  return page === 'shiftDetail' ? 
    apiClient.get(`/shift/${startDateString}/X/download`, {
        headers: authHeader(),
        responseType: "blob",
      })
    : 
    apiClient.get(`/shift/X/download`, {
      params: { startDate: startMs, endDate: endMs },
      headers: authHeader(),
      responseType: "blob",
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => handleError(error));
}
function getShiftDownloadZService(page, startDateString, endDateString) {
  let startMs = new Date(startDateString).getTime();
  let endMs = new Date(endDateString).getTime();
  return page === 'shiftDetail' ? 
    apiClient.get(`/shift/${startDateString}/Z/download`, {
        headers: authHeader(),
        responseType: "blob",
      })
    : 
    apiClient.get(`/shift/Z/download`, {
      params: { startDate: startMs, endDate: endMs },
      headers: authHeader(),
      responseType: "blob",
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => handleError(error));
}
function deleteAccount(accountId) {
  return apiClient
    .delete(`/client_account/${accountId}`, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => handleError(error));
}

function forceCloseShift(myComment, myCurrentCash, myPaymentTypeForForcedClose) {
    return apiClient
        .post(`/shift/stop`, {
            currentCash: myCurrentCash,
            comment: myComment,
            paymentTypeForForcedClose: myPaymentTypeForForcedClose
        }, {
            headers: authHeader(),
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => handleError(error));
}