<template>
  <div>
    <page-header
      iconName="settings.svg"
      :headerTitle="$t('settingsPage.name')"
    />
    <div class="main">
      <div class="rest-table-row table-row row align-items-center">
        <div class="col-12 col-md-6">
          <div class="form-input-container mr-md-3 mt-3 mt-md-3">
            <DesignInput :disabled="true" v-model:customValue="organisation.orgId" :hint="$t('settingsPage.organisationId')" />
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-input-container ml-md-3 mt-3 mt-md-3">
            <DesignInput v-model:customValue="organisation.name" :hint="$t('settingsPage.organisationName')" />
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-input-container mr-md-3 mt-3 mt-md-3">
            <DesignInput v-model:customValue="organisation.vatNumber" :hint="$t('settingsPage.vatNumber')" />
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-input-container ml-md-3 mt-3 mt-md-3">
            <DesignInput v-model:customValue="organisation.address" :hint="$t('settingsPage.address')" />
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-input-container mr-md-3 mt-3 mt-md-3">
            <DesignInput v-model:customValue="organisation.telephone" :hint="$t('settingsPage.telephone')" />
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-input-container ml-md-3 mt-3 mt-md-3">
            <DesignInput v-model:customValue="organisation.website" :hint="$t('settingsPage.website')" />
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-input-container mr-md-3 mt-3 mt-md-3">
            <DesignInput v-model:customValue="organisation.mainPrinterIpAddress" :tooltip-text="$t('settingsPage.mainPrinterTooltip')" :hint="$t('settingsPage.mainPrinterIpAddress')" />
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-input-container ml-md-3 mt-3 mt-md-3">
            <DesignInput v-model:customValue="organisation.contactEmail" :hint="$t('settingsPage.contactEmail')" />
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-input-container mr-md-3 mt-3 mt-md-3">
            <DesignInput v-model:customValue="organisation.logoImageUrl" :hint="$t('settingsPage.logoImageUrl')" />
          </div>
        </div>
        <div class="col-12 col-md-6">
        </div>
        <div class="col-12 button-container row mt-4">
            <design-button class="button" @customClick="submitOrganisationUpdate(organisation)" :loading="editingOrganisation.loading">  {{$t('generic.save')}} </design-button >
        </div>
      </div>
    </div>

    <page-header
      iconName="settings.svg"
      :headerTitle="$t('settingsPage.orgaFlag')"
      class="mt-4" v-if="printerSettings"
    />
    <div class="flagPart">
      <div class="rest-table-row table-row row align-items-center">
        <p class="default-header left-bar-default-inline col-12 ml-1">{{ $t('settingsPage.subSectionPayment') }}</p>
          <div class="col-12 col-md-3">
              <div class="form-input-container mr-md-3 mt-3 mt-md-3">
                  <design-select :fullSize="true" :hint="$t('settingsPage.paymentTypeOfferedEnabled')" :tooltip-text="$t('settingsPage.allowOfferTooltip')" v-model:customValue="organisation.paymentTypeOfferedEnabled">
                      <option :value="true">{{$t('generic.yes')}}</option>
                      <option :value="false">{{$t('generic.no')}}</option>
                  </design-select>
              </div>
          </div>
          <div class="col-12 col-md-3">
              <div class="form-input-container mr-md-3 mt-3 mt-md-3">
                  <design-select :fullSize="true" :hint="$t('settingsPage.paymentTypeTransferEnabled')" :tooltip-text="$t('settingsPage.allowBankTransferTooltip')" v-model:customValue="organisation.paymentTypeTransferEnabled">
                      <option :value="true">{{$t('generic.yes')}}</option>
                      <option :value="false">{{$t('generic.no')}}</option>
                  </design-select>
              </div>
          </div>

          <div class="col-12 col-md-3">
              <div class="form-input-container mt-3 mt-md-3">
                  <design-select :fullSize="true" :hint="$t('settingsPage.autoCloseAccountOnPaymentEnabled')"  :tooltip-text="$t('settingsPage.closeAccountAfterPaymentTooltip')" v-model:customValue="organisation.autoCloseAccountOnPaymentEnabled">
                      <option :value="true">{{$t('generic.yes')}}</option>
                      <option :value="false">{{$t('generic.no')}}</option>
                  </design-select>
              </div>
          </div>
          <div class="col-12 col-md-3">
              <div class="form-input-container ml-md-3 mt-3 mt-md-3">
                  <design-select :fullSize="true" :hint="$t('settingsPage.autoOpenNewAccount')" :tooltip-text="$t('settingsPage.autoOpenNewAccountTooltip')" v-model:customValue="organisation.autoOpenNewAccount">
                      <option :value="true">{{$t('generic.yes')}}</option>
                      <option :value="false">{{$t('generic.no')}}</option>
                  </design-select>
              </div>
          </div>
          <div class="col-12 col-md-3">
              <div class="form-input-container mr-md-3 mt-3 mt-md-3">
                  <design-select :fullSize="true" :hint="$t('settingsPage.autoPrintSummaryTicketEnabled')"  :tooltip-text="$t('settingsPage.autoPrintSummaryTicketTooltip')" v-model:customValue="organisation.autoPrintSummaryTicketEnabled">
                      <option :value="true">{{$t('generic.yes')}}</option>
                      <option :value="false">{{$t('generic.no')}}</option>
                  </design-select>
              </div>
          </div>
          <div class="col-12 col-md-3">
              <div class="form-input-container mr-md-3 mt-3 mt-md-3">
                  <design-select :fullSize="true" :hint="$t('settingsPage.autoOpenCashRegister')" :tooltip-text="$t('settingsPage.openCashRegisterTooltip')" v-model:customValue="organisation.autoOpenCashRegister">
                      <option :value="true">{{$t('generic.yes')}}</option>
                      <option :value="false">{{$t('generic.no')}}</option>
                  </design-select>
              </div>
          </div>
          <div class="col-12 col-md-3">
              <div class="form-input-container mt-3 mt-md-3">
                  <design-select :fullSize="true" :hint="$t('settingsPage.refundEnabled')" :tooltip-text="$t('settingsPage.allowRefundTooltip')" v-model:customValue="organisation.refundEnabled">
                      <option :value="true">{{$t('generic.yes')}}</option>
                      <option :value="false">{{$t('generic.no')}}</option>
                  </design-select>
              </div>
          </div>

      <p class="default-header left-bar-default-inline col-12 ml-1 mt-5">{{ $t('settingsPage.subSectionHome') }}</p>

        <div class="col-12 col-md-3">
          <div class="form-input-container mr-md-3 mt-3 mt-md-3">
            <design-select :fullSize="true" :hint="$t('settingsPage.takeawaySectionEnabled')" v-model:customValue="organisation.takeawaySectionEnabled">
                <option :value="true">{{$t('generic.yes')}}</option>
                <option :value="false">{{$t('generic.no')}}</option>
            </design-select>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-input-container mr-md-3 mt-3 mt-md-3">
            <design-select :fullSize="true" :hint="$t('settingsPage.tableSectionEnabled')" v-model:customValue="organisation.tableSectionEnabled">
                <option :value="true">{{$t('generic.yes')}}</option>
                <option :value="false">{{$t('generic.no')}}</option>
            </design-select>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-input-container mr-md-3 mt-3 mt-md-3">
            <design-select :fullSize="true" :hint="$t('settingsPage.pinLockEnabled')" :tooltip-text="$t('settingsPage.pinLockTooltip')" v-model:customValue="organisation.pinLockEnabled">
                <option :value="true">{{$t('generic.yes')}}</option>
                <option :value="false">{{$t('generic.no')}}</option>
            </design-select>
          </div>
        </div>
          <div class="col-12 col-md-3">
              <div class="form-input-container mr-md-3 mt-3 mt-md-3">
                  <DesignInput v-model:customValue="organisation.pinLockTimeout" :tooltip-text="$t('settingsPage.pinLockTimeoutTooltip')" :hint="$t('settingsPage.pinLockTimeout')" />
              </div>
          </div>

      <p class="default-header left-bar-default-inline col-12 ml-1 mt-5">{{ $t('settingsPage.subSectionShift') }}</p>
        <div class="col-12 col-md-3">
          <div class="form-input-container mr-md-3 mt-3 mt-md-3">
            <design-select :fullSize="true" :hint="$t('settingsPage.shiftEnabled')" :tooltip-text="$t('settingsPage.shiftsTooltip')" v-model:customValue="organisation.shiftsEnabled">
                <option :value="true">{{$t('generic.yes')}}</option>
                <option :value="false">{{$t('generic.no')}}</option>
            </design-select>
          </div>
        </div>

          <div class="col-12 col-md-3">
              <div class="form-input-container mr-md-3 mt-3 mt-md-3">
                  <design-select :fullSize="true" :hint="$t('settingsPage.autoPrintShiftXY')"  :tooltip-text="$t('settingsPage.autoPrintShiftXZTooltip')" v-model:customValue="organisation.autoPrintXZOnShiftClose">
                      <option :value="true">{{$t('generic.yes')}}</option>
                      <option :value="false">{{$t('generic.no')}}</option>
                  </design-select>
              </div>
          </div>

          <div class="col-12 col-md-3">
              <div class="form-input-container mr-md-3 mt-3 mt-md-3">
                  <design-select :fullSize="true" :hint="$t('settingsPage.hideShiftInfo')" :tooltip-text="$t('settingsPage.hideShiftInfoTooltip')" v-model:customValue="organisation.hideShiftInfo">
                      <option :value="true">{{$t('generic.yes')}}</option>
                      <option :value="false">{{$t('generic.no')}}</option>
                  </design-select>
              </div>
          </div>

      <p class="default-header left-bar-default-inline col-12 ml-1 mt-5">{{ $t('settingsPage.subSectionOrdering') }}</p>

        <div class="col-12 col-md-3">
          <div class="form-input-container mr-md-3 mt-3 mt-md-3">
            <design-select :fullSize="true" :hint="$t('settingsPage.kitchenPrintEnabled')"  :tooltip-text="$t('settingsPage.printOrderTicketTooltip')" v-model:customValue="organisation.kitchenPrintEnabled">
                <option :value="true">{{$t('generic.yes')}}</option>
                <option :value="false">{{$t('generic.no')}}</option>
            </design-select>
          </div>
        </div>

        <div class="col-12 col-md-3">
          <div class="form-input-container mr-md-3 mt-3 mt-md-3">
            <design-select :hint="$t('settingsPage.sendOrderBehavior')" :tooltip-text="$t('settingsPage.actionAfterSendTooltip')" v-model:customValue="organisation.sendOrderBehavior">
              <option value="DEFAULT">{{$t('settingsPage.DEFAULT')}}</option>
              <option value="NAV_BACK">{{$t('settingsPage.NAV_BACK')}}</option>
              <option value="NAV_PAYMENT">{{$t('settingsPage.NAV_PAYMENT')}}</option>
          </design-select>
          </div>
        </div>





        <div class="col-12 col-md-3">
          <div class="form-input-container mr-md-3 mt-3 mt-md-3">
            <design-select :fullSize="true" :hint="$t('settingsPage.addItemsToTop')" :tooltip-text="$t('settingsPage.newProductOnTopTooltip')" v-model:customValue="organisation.addItemsToTop">
                <option :value="true">{{$t('generic.yes')}}</option>
                <option :value="false">{{$t('generic.no')}}</option>
            </design-select>
          </div>
        </div>

        <div class="col-12 col-md-3">
          <div class="form-input-container mr-md-3 mt-3 mt-md-3">
            <design-select :fullSize="true" :hint="$t('settingsPage.normalAccountOpenButton')" :tooltip-text="$t('settingsPage.separateInDeliveryTakeawayTooltip')" v-model:customValue="organisation.normalAccountOpenButton">
                <option :value="true">{{$t('generic.yes')}}</option>
                <option :value="false">{{$t('generic.no')}}</option>
            </design-select>
          </div>
        </div>

        <div class="col-12 button-container row mt-4">
            <design-button class="button" @customClick="submitOrganisationUpdate(organisation)" :loading="editingOrganisation.loading">  {{$t('generic.save')}} </design-button >
        </div>
      </div>
    </div>


    <page-header
      iconName="printer.svg"
      :headerTitle="$t('settingsPage.printerSettings')"
      class="mt-4" v-if="printerSettings"
    />
    <div class="main" v-if="printerSettings">
      <div class="rest-table-row table-row row align-items-center">
        <div class="col-12 col-md-6">
          <div class="form-input-container mr-md-3 mt-3 mt-md-3">
            <DesignInput v-model:customValue="printerSettings.kitchenTopSpacing" :hint="$t('settingsPage.kitchenTopSpacing')" />
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-input-container ml-md-3 mt-3 mt-md-3">
            <DesignInput v-model:customValue="printerSettings.charactersPerLine" :hint="$t('settingsPage.charactersPerLine')" />
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-input-container mt-3 mt-md-3">
            <design-select :fullSize="true" :hint="$t('settingsPage.kitchenGroupItems')" v-model:customValue="printerSettings.kitchenGroupItems">
                <option :value="true">{{$t('generic.yes')}}</option>
                <option :value="false">{{$t('generic.no')}}</option>
            </design-select>
          </div>
        </div>
        <div class="col-12 col-md-6"></div>
        <div class="button-container row mt-4">
            <design-button class="button" @customClick="submitPrinterUpdate(printerSettings)" :loading="editingOrganisation.loading">  {{$t('generic.save')}} </design-button >
        </div>
      </div>
    </div>

    <page-header
        iconName="settings.svg"
        :headerTitle="$t('settingsPage.webSettings')"
        class="mt-4" v-if="organisation.onlineOrderWebPageEnabled"
    />
    <div class="flagWebPart" v-if="organisation.onlineOrderWebPageEnabled">
      <div class="rest-table-row table-row row align-items-center">
        <p class="default-header left-bar-default-inline col-12 ml-1">{{ $t('settingsPage.subSectionWebsite') }}</p>
        <div class="col-12 col-md-3">
          <div class="form-input-container mr-md-3 mt-3 mt-md-3">
            <design-select :fullSize="true" :hint="$t('settingsPage.onlineOrderOpen')" v-model:customValue="organisation.onlineOrderOpen">
              <option :value="true">{{$t('generic.yes')}}</option>
              <option :value="false">{{$t('generic.no')}}</option>
            </design-select>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-input-container mr-md-3 mt-3 mt-md-3">
            <design-select :fullSize="true" :hint="$t('settingsPage.onlineOrderDeliveryEnabled')" v-model:customValue="organisation.onlineOrderDeliveryEnabled">
              <option :value="true">{{$t('generic.yes')}}</option>
              <option :value="false">{{$t('generic.no')}}</option>
            </design-select>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-input-container mr-md-3 mt-3 mt-md-3">
            <design-select :fullSize="true" :hint="$t('settingsPage.payOnlineEnabled')" v-model:customValue="organisation.payOnlineEnabled">
              <option :value="true">{{$t('generic.yes')}}</option>
              <option :value="false">{{$t('generic.no')}}</option>
            </design-select>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-input-container mr-md-3 mt-3 mt-md-3">
            <design-select :fullSize="true" :hint="$t('settingsPage.payAtPickupEnabled')" v-model:customValue="organisation.payAtPickupEnabled">
              <option :value="true">{{$t('generic.yes')}}</option>
              <option :value="false">{{$t('generic.no')}}</option>
            </design-select>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-input-container mr-md-3 mt-3 mt-md-3">
            <design-select :fullSize="true" :hint="$t('settingsPage.onlineOrderHideNoStockProducts')" v-model:customValue="organisation.onlineOrderHideNoStockProducts">
              <option :value="true">{{$t('generic.yes')}}</option>
              <option :value="false">{{$t('generic.no')}}</option>
            </design-select>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-input-container mr-md-3 mt-3 mt-md-3">
            <DesignInput v-model:customValue="organisation.bannerImageUrl" :hint="$t('settingsPage.bannerImageUrl')" />

          </div>
        </div>
        <div class="col-12 button-container row mt-4">
          <design-button class="button" @customClick="submitOrganisationUpdate(organisation)" :loading="editingOrganisation.loading">  {{$t('generic.save')}} </design-button >
        </div>
      </div>
    </div>

      <page-header
          iconName="terminal.svg"
          :headerTitle="$t('settingsPage.superSettings')"
          class="mt-4" v-if="isSuperUser"
      />

      <div class="main" v-if="isSuperUser">
          <div class="rest-table-row table-row row align-items-center">
              <p class="default-header left-bar-default-inline col-12 ml-1">Vivawallet</p>
              <div class="col-12 col-md-6">
                  <div class="form-input-container mr-md-3 mt-3 mt-md-3">
                      <design-select :fullSize="true" :hint="$t('settingsPage.vwEnabled')"
                                     v-model:customValue="organisation.vwEnabled">
                          <option :value="true">{{ $t('generic.yes') }}</option>
                          <option :value="false">{{ $t('generic.no') }}</option>
                      </design-select>
                  </div>
              </div>
              <div class="col-12 col-md-6">
                  <div class="form-input-container mr-md-3 mt-3 mt-md-3">
                      <DesignInput v-model:customValue="organisation.vwTerminalMerchantId"
                                   :hint="$t('settingsPage.vwTerminalMerchantId')"/>
                  </div>
              </div>
              <div class="col-12 col-md-6">
                  <div class="form-input-container mr-md-3 mt-3 mt-md-3">
                      <DesignInput v-model:customValue="organisation.vwIsvAmount"
                                   :hint="$t('settingsPage.vwIsvAmount')"/>
                  </div>
              </div>
              <div class="col-12 col-md-6">
                  <div class="form-input-container mr-md-3 mt-3 mt-md-3">
                      <DesignInput v-model:customValue="organisation.vwTerminalId"
                                   :hint="$t('settingsPage.vwTerminalId')"/>
                  </div>
              </div>

            <p class="default-header left-bar-default-inline col-12 ml-1 mt-5">Webshop</p>
            <div class="col-12">
              <div class="form-input-container mr-md-3 mt-3 mt-md-3">
                <design-select :fullSize="true" :hint="$t('settingsPage.onlineOrderWebPageEnabled')" v-model:customValue="organisation.onlineOrderWebPageEnabled">
                  <option :value="true">{{$t('generic.yes')}}</option>
                  <option :value="false">{{$t('generic.no')}}</option>
                </design-select>
              </div>
            </div>

              <p class="default-header left-bar-default-inline col-12 ml-1 mt-5">Google Maps</p>
              <div class="col-12">
                  <div class="form-input-container mr-md-3 mt-3 mt-md-3">
                      <design-select :fullSize="true" :hint="$t('settingsPage.mapsAutoCompleteEnabled')"
                                     v-model:customValue="organisation.mapsAutoCompleteEnabled">
                          <option :value="true">{{ $t('generic.yes') }}</option>
                          <option :value="false">{{ $t('generic.no') }}</option>
                      </design-select>
                  </div>
              </div>

              <p class="default-header left-bar-default-inline col-12 ml-1 mt-5">Device Management</p>
              <div class="col-12">
                  <div class="form-input-container mr-md-3 mt-3 mt-md-3">
                      <design-select :fullSize="true" :hint="$t('settingsPage.autoAssignDeviceToMainType')"
                                     v-model:customValue="organisation.autoAssignDeviceToMainType">
                          <option :value="true">{{ $t('generic.yes') }}</option>
                          <option :value="false">{{ $t('generic.no') }}</option>
                      </design-select>
                  </div>
              </div>

              <p class="default-header left-bar-default-inline col-12 ml-1 mt-5">Parent Organization</p>
              <div class="col-12">
                  <div class="form-input-container mr-md-3 mt-3 mt-md-3">
                      <DesignInput v-model:customValue="organisation.parentOrgId"
                                   :hint="$t('settingsPage.parentOrgId')"/>
                  </div>
              </div>

              <div class="col-12">
                  <div class="form-input-container mr-md-3 mt-3 mt-md-3">
                      <design-select :fullSize="true" :hint="$t('settingsPage.includeParentProductsHint')"
                                     :tooltip-text="$t('settingsPage.includeParentProductsTooltip')"
                                     v-model:customValue="organisation.includeParentProducts">
                          <option :value="true">{{ $t('generic.yes') }}</option>
                          <option :value="false">{{ $t('generic.no') }}</option>
                      </design-select>
                  </div>
              </div>

              <div class="button-container row mt-4">
                  <design-button class="button" @customClick="submitOrganisationUpdate(organisation)" :loading="editingOrganisation.loading">  {{$t('generic.save')}} </design-button >
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import PageHeader from './components/PageHeader.vue';
import DesignButton from './components/DesignButton.vue';
import DesignInput from './components/DesignInput.vue';
import DesignSelect from './components/DesignSelect.vue';
import { mapActions, mapState } from "vuex";
export default {
  name: "MySettingsPage",
  components: {
    PageHeader,
    DesignButton,
    DesignInput,
    DesignSelect
  },
  computed: {
    ...mapState('organisation', ['organisation', 'orgaFlag', 'printerSettings', 'editingOrganisation']),
    ...mapState('account', ['isSuperUser'])
  },
  methods: {
    ...mapActions('organisation', ['getOrganisation', 'updateOrganisation']),
    getOrganisations(){
      this.getOrganisation();
    },
    submitOrganisationUpdate(form){
      let organisationUpdate = form;
      this.updateOrganisation(organisationUpdate);
    },
    submitPrinterUpdate(form){
      let organisationUpdate = {
        ...this.organisation,
        printerSettings : form
      };
      this.updateOrganisation(organisationUpdate);
    }
  },
  mounted() {
      this.getOrganisations();
  }
}
</script>

<style lang="scss" scoped>
    .default-header {
        padding-left: $default-edge-spacing !important;
    }
</style>